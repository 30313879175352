div#offers-table-block{
    border: black solid 1px;
    border-radius: 7px;
    padding: 5px;
    overflow: auto;
}

.edit{
    color: red;
}

.run{
    color: greenyellow;
}

.run-table{
    background-color: lightgoldenrodyellow;
    border-collapse: collapse;
    border: 2px solid rgb(200, 200, 200);
}

.edit-table{
    background-color: lightcoral;
    border-collapse: collapse;
    border: 2px solid rgb(200, 200, 200);
}

table, th, td {
    border: 1px solid black;
  }

list{
    list-style-type:none;
}

  